<template>
  <div class="en_add">
    <el-card>
      <el-form
        :model="form"
        ref="form"
        :rules="rule"
        label-width="110px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="企业简称" prop="name">
          <el-col :span="8">
            <el-input v-model="form.name" :maxlength="16" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="企业全称" prop="fullName">
          <el-col :span="8">
            <el-input v-model="form.fullName" />
          </el-col>
        </el-form-item>
        <el-form-item label="企业logo" prop="logo">
          <imgup
            :action="action"
            :banner="form.logo"
            @event="setImg"
            :tip_text="false"
          ></imgup>
        </el-form-item>
        <el-form-item label="公司官网" prop="website">
          <el-col :span="8">
            <el-input v-model="form.website" />
          </el-col>
        </el-form-item>
        <el-form-item label="实际办公地址" prop="city">
          <el-select
            v-model="form.province"
            placeholder="省份"
            style="width: 130px; margin-right: 12px"
            @change="op"
          >
            <el-option
              v-for="item in province"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
          <el-select
            v-model="form.city"
            placeholder="城市"
            style="width: 130px; margin-right: 12px"
          >
            <el-option
              v-for="item in city"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
          <div class="box">
            <el-input v-model="form.address" :maxlength="100" show-word-limit />
          </div>
        </el-form-item>
        <el-form-item label="公司人数" prop="people">
          <el-select v-model="form.people" placeholder="请选择公司人数">
            <el-option
              v-for="(item, index) in $store.state.enterprise_config &&
                $store.state.enterprise_config.enterprisePeople"
              :key="item.key"
              :label="item.value"
              :value="item.key * 1"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="融资情况" prop="financing">
          <el-select v-model="form.financing" placeholder="请选择融资情况">
            <el-option
              v-for="(item, index) in $store.state.enterprise_config &&
                $store.state.enterprise_config.enterpriseFinancing"
              :key="index"
              :label="item.value"
              :value="item.key * 1"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-checkbox-group v-model="form.type" :max="3">
            <el-checkbox
              :label="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.type"
              :key="index"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="行业" prop="business">
          <el-checkbox-group v-model="form.business" :max="3">
            <el-checkbox
              :label="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="业务区域" prop="serverArea">
          <el-checkbox-group v-model="form.serverArea" :max="3">
            <el-checkbox
              :label="item.key + ''"
              v-for="(item, index) in $store.state.config &&
                $store.state.enterprise_config.enterpriseServerArea"
              :key="index"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="企业简介" prop="intro">
          <el-col :span="8" :offset="0">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="form.intro"
            >
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="企业介绍" prop="companyDesc">
          <el-col :span="8" :offset="0">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="form.companyDesc"
            >
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="产品介绍" prop="productDesc">
          <el-col :span="8" :offset="0">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="form.productDesc"
            >
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="产品图片">
          <imgups
            :action="action"
            :list="this.form.productImages"
            @changes="c_img"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '提交'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '../../components/upImg/index.vue'
import rules from '../../utils/rules'
import imgups from '../../components/uplist/index.vue'
export default {
  data() {
    return {
      id: null,
      form: {
        name: '',
        fullName: '',
        logo: '',
        website: '',
        province: '',
        city: '',
        address: '',
        people: '',
        financing: '',
        type: [],
        business: [],
        serverArea: [],
        companyDesc: '',
        productDesc: '',
        productImages: []
      },

      rule: {
        name: [rules.req('请输入企业简称')],
        fullName: [rules.req('请输入企业全称')],
        logo: [rules.req('请输入企业logo')],
        city: [rules.req('请输入省份和城市')],
        people: [rules.req('请输入公司人事')],
        financing: [rules.req('请输入融资情况')],
        type: [rules.req('请输入类型')],
        business: [rules.req('请输入行业')],
        serverArea: [rules.req('请输入业务区域')],
        companyDesc: [rules.req('请输入企业介绍')],
        intro: [rules.req('请输入企业简介')]
      },
      action: {
        action: 'enterprise'
      },
      province: {},
      city: {},
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain
    }
  },
  created() {
    this.getform()
    this.getp()
    this.bus.$on('new', () => {
      this.form = {
        name: '',
        logo: '',
        website: '',
        province: '',
        fullName: '',
        city: '',
        address: '',
        people: '',
        financing: '',
        type: 1,
        business: [],
        serverArea: [],
        companyDesc: '',
        productDesc: '',
        productImages: []
      }
      this.id = null
    })
  },
  methods: {
    c_img(val) {
      this.form.productImages = val
    },

    async getp() {
      let res = await this.$store.dispatch('getProvince')
      this.province = res.data
      //   setTimeout(()=>{
      //   },200)
    },
    async getc(val) {
      if (!val) this.form.city = ''
      let { data: res } = await this.$store.dispatch(
        'getCity',
        this.form.province
      )
      this.city = res
    },
    op() {
      this.getc()
    },
    async onSubmit() {
      var url = this.form.productImages.map(item => item.url)
      url = url.join(',')
      if (this.id) {
        var { data: res } = await this.$http.post('/admin/Enterprise/edit', {
          ...this.form,
          business: this.form.business.join(','),
          serverArea: this.form.serverArea.join(','),
          type: this.form.type.toString(),
          productImages: url,
          id: this.id
        })
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          history.back()
        } else {
          this.$message.error(res.message)
        }
      } else {
        var { data: res } = await this.$http.post('/admin/Enterprise/add', {
          ...this.form,
          business: this.form.business.join(','),
          serverArea: this.form.serverArea.join(','),
          type: this.form.type.toString(),
          productImages: url
        })
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.$router.push('/enterprise_cert_list')
        } else {
          this.$message.error(res.message)
        }
      }
    },
    async getform() {
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/Enterprise/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              var yyds = res.data.productImages.split(',')
              var url = []
              yyds.forEach(item => {
                if (!!item) {
                  url.push({
                    name: '1',
                    url: item
                  })
                }
              })
              console.log(url)
              this.form = {
                ...res.data,
                business: res.data.business.split(',').filter(Boolean),
                serverArea: res.data.serverArea.split(',').filter(Boolean),
                type: res.data.type.toString().split(','),
                productImages: url
              }
            this.form.province && this.getc(1)

              // console.log(this.form)
              this.form.id = id * 1
              //  this.form.initViews =
              //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    setImg(val) {
      this.form.logo = val
    }
  },
  computed: {},
  components: {
    imgup,
    imgups
  }
}
</script>

<style lang="less">
.en_add {
  .box {
    display: inline-block;
    width: 300px;
    input {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>
